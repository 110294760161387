import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useRecoilValue } from "recoil";
import { linksAtom } from "state/onlineForms";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);

const LinksComp = (props) => {
  const classes = useStyles();
  const fixOrderForBryk = (a, b) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
  }

  const links = useRecoilValue(linksAtom)
  const groups = links.map(item => item.groupID).filter((item, i, ar) => ar.indexOf(item) === i)
  return (
    <React.Fragment>
      <GridContainer>
        {groups.map(item => {
          return (
            <GridItem xs={12} sm={6} lg={3} key={item}>
              <Card style={{ marginBottom: '10px' }}>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitleWhite}>{item}</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {/* <ul className={classes.linksList}> */}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {[...links].sort(fixOrderForBryk).filter(i => i.groupID === item).map((link, index) => {
                      return (
                        <div key={index}>
                          <Button
                            style={{ padding: '5px 10px 3px 10px', marginRight: '5px', backgroundColor: '#eee', color: '#444', textTransform: 'none' }}
                            onClick={() => {
                              window.open(link.link, "_blank")
                            }}
                          >
                            {link.name}
                          </Button>
                        </div>
                      )
                    })}
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          )
        })}
      </GridContainer>
    </React.Fragment>
  );
}

const Links = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <LinksComp {...props} />
    </React.Suspense>
  )
};

export default Links;
