import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

export const checkinGroupsAtom = selector({
  key: "checkinGroups",
  get: async () => {
    return await axios
      .get(`${api.path}/staff_portal_api/v1/checkingroups`)
      .then((res) => {
        return res.data;
      });
  },
});

/* export const checkinTeamEmailAtom = atom({
  key: "checkinTeamEmail",
  default: "internal-cit-jon@sagebrush.church",
}); */

export const checkinTeamState = selectorFamily({
  key: "checkinTeamData",
  get: (email) => async ({ get }) => {
    const teamData = await axios
      .post(`${api.path}/staff_portal_api/v1/teamcheckinoverview`, {
        groupemail: email,
        startdate: get(checkinStartDateAtom),
        enddate: get(checkinEndDateAtom),
      })
      .then((res) => {
        return {
          email: email,
          teamData: res.data,
        };
      });

    return teamData;
  },
});

export const checkinStartDateAtom = atom({
  key: "checkinStartDate",
  default: null,
});

export const checkinEndDateAtom = atom({
  key: "checkinEndDate",
  default: null,
});

export const checkinSingleUserAtom = atom({
  key: "checkinSingleUser",
  default: null,
});

// Single User View stuff below
export const checkinSingleAtom = selectorFamily({
  key: "checkinSingleSelector",
  get: (email) => async ({ get, set }) => {
    return axios
      .post(`${api.path}/staff_portal_api/v1/usercheckindata`, {
        useremail: email,
        startdate: get(checkinSingleStartDateAtom),
        enddate: get(checkinSingleEndDateAtom),
      })
      .then((res) => {
        let tempData = [];

        res.data.records.map((item) => {
          tempData.push([
            <>
              <p>{item.checkin.timestamp}</p>
              {item.checkin.notes !== "" && <p>Note: {item.checkin.notes}</p>}
            </>,
            item.checkout.timestamp ? (
              <>
                <p>{item.checkout.timestamp}</p>
                {item.checkout.notes !== "" && (
                  <p>Note: {item.checkout.notes}</p>
                )}
              </>
            ) : (
              "----"
            ),
            <>
              In:{" "}
              {item.checkin.location === "Remote" ? (
                <a
                  href={`https://maps.google.com/?q=${item.checkin.coords}`}
                  target="_blank"
                >
                  Remote - View Map
                </a>
              ) : (
                item.checkin.location
              )}
              <br />
              Out:{" "}
              {item.checkout.location === "Remote" ? (
                <a
                  href={`https://maps.google.com/?q=${item.checkout.coords}`}
                  target="_blank"
                >
                  Remote - View Map
                </a>
              ) : (
                item.checkout.location
              )}
            </>,
            item.checkout.timePeriod &&
              (item.checkout.timePeriod.hours ||
                item.checkout.timePeriod.minutes ||
                item.checkout.timePeriod.seconds)
              ? item.checkout.timePeriod.hours +
              ":" +
              item.checkout.timePeriod.minutes.toString().padStart(2, "0") +
              ":" +
              item.checkout.timePeriod.seconds.toString().padStart(2, "0")
              : "--",
          ]);
        });

        return { userData: res.data.user, tableData: tempData };

        //setTableData(tempData);
        //setUserData(res.data.user);
      });
  },
});

export const checkinSingleOverviewAtom = selectorFamily({
  key: "checkinSingleOverviewAtom",
  get: (email) => async ({ get }) => {
    return await axios
      .post(`${api.path}/staff_portal_api/v1/usercheckinoverview`, {
        emailaddr: email,
        startdate: get(checkinSingleStartDateAtom),
        enddate: get(checkinSingleEndDateAtom),
      })
      .then((res) => {
        return res.data[0];
      })
      .catch((err) => { });
  },
});

export const checkinSingleStartDateAtom = atom({
  key: "checkinSingleStartDate",
  default: selector({
    key: "checkinSingleStartDateSelector",
    get: () => {
      const d = new Date();
      const sd = d.setDate(d.getDate() - (d.getDay() + 1));

      return new Date(sd).toISOString().slice(0, 10);
    },
  }),
});

export const checkinSingleEndDateAtom = atom({
  key: "checkinSingleEndDate",
  default: selector({
    key: "checkinSingleEndDateSelector",
    get: () => {
      return new Date().toISOString().slice(0, 10);
    },
  }),
});
