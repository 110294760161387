import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Loading from "components/Loading/Loading";
import Checkbox from '@material-ui/core/Checkbox';
import update from "immutability-helper";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CardBody from "components/Card/CardBody.js";
import { useRecoilValue } from "recoil";

import {
  Close,
} from "@material-ui/icons";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  newTicketMessage,
  activeUsersAtom
} from "state/ticketSystem";
import { loadAsync } from "jszip";

const TicketChangePopupComp = ({ type, setType, tickets, updateData, setLoading, selectableDepartments, departmentSelectableOptions, ticketStates }) => {
  const formats = [
    'bold',
    'color',
    'italic',
    'strike',
    'underline',
    'link',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
  ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ list: "ordered" }],
      [{
        'color': [
          '#99a3a4', '#7f8c8d', '#707b7c', '#616a6b', '#515a5a', '#424949', '#000000',
          '#e6b0aa', '#d98880', '#cd6155', '#c0392b', '#a93226', '#922b21', '#641e16',
          '#f5cba7', '#f0b27a', '#eb984e', '#e67e22', '#ca6f1e', '#af601a', '#935116',
          '#f7dc6f', '#f4d03f', '#f1c40f', '#d4ac0d', '#b7950b', '#9a7d0a', '#7d6608',
          '#82e0aa', '#58d68d', '#2ecc71', '#28b463', '#239b56', '#1d8348', '#186a3b',
          '#7fb3d5', '#5499c7', '#2980b9', '#2471a3', '#1f618d', '#1a5276', '#154360',
          '#bb8fce', '#a569bd', '#8e44ad', '#7d3c98', '#6c3483', '#5b2c6f', '#4a235a',
        ]
      }],
    ],
  }
  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      width: "96vw",
      maxWidth: '800px',
      margin: "0",
      boxShadow: "0px 0px 20px 5px #333",
      zIndex: 99999,
    },
  };
  const uniqueValues = (arrays) => {
    let unique = []
    arrays.forEach(i => unique.concat(i))
    return Array.from(new Set(unique))
  }
  const [text, setText] = useState('');
  const [department, setDepartment] = useState(null)
  const status = type === 'Status' ? tickets[0].status === 'Open' ? 'Closed' : 'Open' : null
  const [priority, setPriority] = useState('normal')
  const [user, setUser] = useState(tickets.length === 1 ? tickets[0].user : {})
  const [cc, setCC] = useState(tickets.length === 1 ? Array.isArray(tickets[0].collaborators) ? tickets[0].collaborators : [] : [])
  const activeUsers = useRecoilValue(activeUsersAtom)
  const defaultTicketState = ticketStates ? tickets.length === 1 ? ticketStates.find(t => t.state === tickets[0].state) ? ticketStates.find(t => t.state === tickets[0].state) : null : null : null
  const [ticketState, setTicketState] = useState(defaultTicketState)
  const defaultTags = tickets.length === 1 ? tickets[0].tags : tickets.length === 0 ? [] : uniqueValues(tickets.map(i => i.tags))
  const [tags, setTags] = useState(defaultTags)

  const [needBy, setNeedBy] = useState('')
  const [location, setLocation] = useState('')
  const [subject, setSubject] = useState(tickets.length === 1 ? tickets[0].subject : '')

  const emptyErrors = {
    user: '',
    cc: cc.length > 0 ? cc.map(i => { return '' }) : []
  }

  const [usersErrors, setUsersErrors] = useState(emptyErrors)

  const selectedUsers = tickets.length === 1 ? Array.isArray(tickets[0].collaborators) ? cc.concat([user]) : [] : []

  const inactiveUsers = tickets.length === 1 ? Array.isArray(tickets[0].collaborators) ? tickets[0].collaborators.concat([tickets[0].user]).filter(i => i.type === 'User' && i.status !== 'active') : [] : []

  const users = activeUsers.map(item => {
    return {
      ...item,
      id: item.userID
    }
  })

  const dropdownTypes = ['All', 'Group', 'User']
  const collaboratorTypes = [
    {
      value: 'User',
      label: 'Staff'
    },
    {
      value: 'Email',
      label: 'Non-Staff'
    },
  ]

  const [assignedTo, setAssignedTo] = useState([{
    id: 'All',
    name: 'All',
    type: 'All'
  }])

  const addNewCC = () => {
    let entry = {
      id: null,
      name: null,
      type: 'User'
    }
    setCC((prevState) => {
      return update(prevState, {
        $push: [entry]
      })
    })

    setUsersErrors((prevState) => {
      return update(prevState, {
        cc: { $push: [''] }
      })
    })
  }

  const updateCC = (item, index) => {
    setCC((prevState) => {
      return update(prevState, {
        [index]: { $set: item }
      })
    })
  }

  const removeCC = (index) => {
    setCC((prevState) => {
      return update(prevState, {
        $splice: [[index, 1]]
      })
    })
    setUsersErrors((prevState) => {
      return update(prevState, {
        cc: { $splice: [[index, 1]] }
      })
    })
  }

  const addNewAssignTo = () => {
    let entry = {
      id: null,
      name: null,
      type: 'User'
    }
    setAssignedTo((prevState) => {
      return update(prevState, {
        $push: [entry]
      })
    })
  }

  const updateAssignedTo = (item, index) => {
    setAssignedTo((prevState) => {
      return update(prevState, {
        [index]: { $set: item }
      })
    })
  }

  const removeAssignedTo = (index) => {
    setAssignedTo((prevState) => {
      return update(prevState, {
        $splice: [[index, 1]]
      })
    })
    if (assignedTo.length === 1) {
      addNewAssignTo()
    }
  }

  const filterOutExisting = (options, alreadySelected, type, currentVal = null) => {
    let newOptions = []
    if (type === 'User') {
      options.forEach(item => {
        if (!alreadySelected.find(i => i.id === item.id)) {
          newOptions.push(item)
        } else if (currentVal) {
          if (currentVal.id === item.id) {
            newOptions.push(item)
          }
        }
      })
    }

    if (type === 'Group') {
      options.forEach(item => {
        if (!alreadySelected.find(i => i.id === item.id)) {
          newOptions.push(item)
        } else if (currentVal) {
          if (currentVal.id === item.id) {
            newOptions.push(item)
          }
        }
      })
    }

    if (type === 'Type') {
      if (alreadySelected.find(i => i.type === 'All') && currentVal !== 'All') {
        return options.filter(i => i !== 'All')
      }
      return options
    }

    return newOptions
  }

  const setTicketStatus = async (status, message) => {
    setType('')
    setLoading(true)

    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      if (message.replaceAll(' ', '') !== '<p></p>' && message.trim() !== '' && message.replaceAll(' ', '') !== '<p><br></p>') {
        formData.append('msgType', 'internal')
        formData.append('html', message)
        formData.append('newStatus', status)
        formData.append('ticketID', tickets[i].id)
      } else {
        formData.append('msgType', 'status')
        formData.append('newStatus', status)
        formData.append('ticketID', tickets[i].id)
      }
      await newTicketMessage(formData)
    }
    updateData()
  }

  const setTicketAssignedTo = async (assignedTo) => {
    setType('')
    setLoading(true)
    let all = assignedTo.find(i => i.id === 'All')
    if (all) {
      assignedTo = [all]
    }
    assignedTo = assignedTo.map(item => item.id)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'assignTo')
      assignedTo.forEach(item => {
        formData.append('assignTo', item);
      })
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const transferTicket = async (newDepartment, message) => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'transfer')
      formData.append('newDepartmentID', newDepartment.id)
      formData.append('html', message)
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const updateTicketState = async (state) => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'state')
      if (state) {
        formData.append('newState', state.state)
      } else {
        formData.append('newState', ' ')
      }
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const updateTicketTags = async (tags) => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'tags')
      formData.append('newTags', tags)
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const setTicketsPriority = async () => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      if (tickets[i].priority !== priority) {
        let formData = new FormData()
        formData.append('msgType', 'priority')
        formData.append('priority', priority)
        formData.append('html', text)
        formData.append('ticketID', tickets[i].id)
        await newTicketMessage(formData)
      }
    }
    updateData()
  }

  const mergeTickets = async () => {
    setType('')
    setLoading(true)
    let formData = new FormData()
    formData.append('msgType', 'merge')
    tickets.forEach((ticket, index) => {
      if (index === 0) {
        formData.append('ticketID', ticket.id)
      } else {
        formData.append('otherTicketID', ticket.id)
      }
    })
    await newTicketMessage(formData)
    updateData()
  }

  const setTicketsNeedBy = async () => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'needBy')
      if (needBy !== '') {
        formData.append('needBy', needBy)
      }
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const setTicketsLocation = async () => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'location')
      if (location !== '') {
        formData.append('location', location)
      }
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const setTicketSubject = async () => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'subject')
      formData.append('subject', subject)
      formData.append('ticketID', tickets[i].id)
      await newTicketMessage(formData)
    }
    updateData()
  }

  const validateNewUsers = () => {
    let invalid = false
    let errors = emptyErrors
    if (!user.id) {
      invalid = true
      errors.user = '*Cannot be left blank.'
    } else {
      if (user.type === 'Email') {
        if (!validateEmail(user.id)) {
          errors.user = '*Invalid email.'
          invalid = true
        } else if (user.id.toLowerCase().includes('@sagebrush')) {
          errors.user = '*Email is a staff email.'
          invalid = true
        }
      }
    }

    cc.forEach((i, index) => {
      if (!i.id) {
        errors.cc[index] = `*Cannot be left blank.`
        invalid = true
      } else {
        if (i.type === 'Email') {
          if (!validateEmail(i.id)) {
            errors.cc[index] = '*Invalid email.'
            invalid = true
          } else if (i.id.toLowerCase().includes('@sagebrush')) {
            errors.cc[index] = '*Email is a staff email.'
            invalid = true
          }
        }
      }
    })

    setUsersErrors(errors)
    return invalid
  }

  const hasUserChanged = () => {
    let hasChanged = false
    let ticket = tickets[0]
    if (user.id !== ticket.user.id) {
      hasChanged = true
    }
    return hasChanged
  }

  const hasCCChanged = () => {
    let hasChanged = false
    let ticket = tickets[0]
    ticket.collaborators.forEach(i => {
      if (!cc.find(j => j.id === i.id)) {
        hasChanged = true
      }
    })
    if (ticket.collaborators.length !== cc.length) {
      hasChanged = true
    }
    return hasChanged
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const setTicketUsers = async () => {
    setType('')
    setLoading(true)
    for (let i = 0; i < tickets.length; i++) {
      let formData = new FormData()
      formData.append('msgType', 'collaborators')
      formData.append('ticketID', tickets[i].id)
      if (hasUserChanged()) {
        formData.append('newTicketOwner', user.id)
      }
      if (hasCCChanged()) {
        if (cc.length === 0) {
          formData.append('newCollaborators', '[]')
        } else {
          cc.forEach(j => {
            formData.append('newCollaborators', j.id)
          })
        }
      }
      await newTicketMessage(formData)
    }
    updateData()
  }

  useEffect(() => {
    setText('')
    setDepartment(null)
    setAssignedTo([{
      id: 'All',
      name: 'All',
      type: 'All'
    }])
    setPriority(tickets.length === 1 ? tickets[0].priority : 'normal')
    setUser(tickets.length === 1 ? tickets[0].user : null)
    setCC(tickets.length === 1 ? tickets[0].collaborators : [])
    setTicketState(defaultTicketState)
    setTags(defaultTags)
    setNeedBy('')
    setLocation('')
  }, [type])

  return (
    <>
      {(type !== '') && (<React.Fragment>
        {ReactDOM.createPortal(
          <div style={styles.wrap} onClick={(e) => setType('')}></div>,
          document.getElementById("file-list-modal-overlay")
        )}
        {ReactDOM.createPortal(
          <div style={{ position: 'absolute', width: '100vw', top: '10vh' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={styles.modal}>
                <CardHeader color="info" text>
                  <CardText color="info" style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
                    {(type === 'Status') && (
                      <>
                        Change Status to {status}
                      </>
                    )}
                    {(type === 'Assign') && (
                      <>
                        Assign Tickets
                      </>
                    )}
                    {(type === 'Transfer') && (
                      <>
                        Transfer Tickets
                      </>
                    )}
                    {(type === 'Merge') && (
                      <>
                        Merge Tickets
                      </>
                    )}
                    {(type === 'Priority') && (
                      <>
                        Set Ticket Priority
                      </>
                    )}
                    {(type === 'Users') && (
                      <>
                        Ticket Users
                      </>
                    )}
                    {(type === 'NeedBy') && (
                      <>
                        Need By
                      </>
                    )}
                    {(type === 'Location') && (
                      <>
                        Location
                      </>
                    )}
                    {(type === 'State') && (
                      <>
                        State
                      </>
                    )}
                    {(type === 'Tags') && (
                      <>
                        Tags
                      </>
                    )}
                    {(type === 'Subject') && (
                      <>
                        New Subject
                      </>
                    )}
                  </CardText>
                  <div style={{ minWidth: '32px', textAlign: 'right', marginTop: '-23px', marginRight: '-3px' }}>
                    <Button
                      justIcon
                      round
                      color="danger"
                      size="sm"
                      onClick={(e) => setType('')}
                    >
                      <Close />
                    </Button>
                  </div>
                </CardHeader>

                <CardBody style={{ maxHeight: "80vh", overflowY: "auto" }}>

                  {(type === 'Status') && (
                    <>
                      <span style={{ fontWeight: 400 }}>Internal Note:</span>
                      <br />
                      <ReactQuill
                        onChange={(content, delta, source, editor) => {
                          setText(editor.getHTML())
                        }}
                        value={text}
                        style={{ paddingBottom: "65px", height: '200px' }}
                        modules={modules}
                        formats={formats}
                      />
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            setTicketStatus(status, text)
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                  {(type === 'Assign') && (
                    <>
                      <span >Assigned To:</span>
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "3px",
                          background: "#fafafa",
                          padding: "10px",
                          minWidth: '290px',
                          overflowX: 'auto',
                          marginBottom: '15px'
                        }}
                      >
                        {assignedTo.map((item, index) => {
                          return (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }} key={item.id}>
                                <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                                  <Autocomplete
                                    disablePortal
                                    options={filterOutExisting(dropdownTypes, assignedTo, 'Type', item.type)}
                                    fullWidth
                                    getOptionLabel={(option) =>
                                      option}
                                    value={item.type}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Type" />
                                    )}
                                    ListboxProps={
                                      {
                                        style: {
                                          maxHeight: '120px',
                                        }
                                      }
                                    }
                                    onChange={(e, v) => {
                                      if (v !== item.type) {
                                        let entry = {
                                          id: null,
                                          name: null,
                                          type: v
                                        }
                                        if (v === 'All') {
                                          entry.name = 'All'
                                          entry.id = 'All'
                                        }
                                        updateAssignedTo(entry, index)
                                      }
                                    }
                                    }
                                    autoSelect={true}
                                    autoComplete={true}
                                    autoHighlight={true}
                                    disableClearable
                                  />
                                </div>
                                <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                                  {item.type !== 'All' && (
                                    <>
                                      {item.type === 'Group' ?
                                        <Autocomplete
                                          disablePortal
                                          options={filterOutExisting(departmentSelectableOptions.groups, assignedTo, item.type, item)}
                                          fullWidth
                                          getOptionSelected={(selected) => {
                                            return selected.id === item.id
                                          }}
                                          getOptionLabel={(option) => {
                                            return option.name
                                          }}
                                          value={item.id ? item : null}
                                          renderInput={(params) => (
                                            <TextField {...params} label={item.type} />
                                          )}
                                          onChange={(e, v) => {
                                            updateAssignedTo(v, index)
                                          }
                                          }
                                          autoSelect={true}
                                          autoComplete={true}
                                          autoHighlight={true}
                                          disableClearable
                                          ListboxProps={
                                            {
                                              style: {
                                                maxHeight: '120px',
                                              }
                                            }
                                          }
                                        />
                                        :
                                        <Autocomplete
                                          disablePortal
                                          options={filterOutExisting(departmentSelectableOptions.users, assignedTo, item.type, item)}
                                          fullWidth
                                          getOptionSelected={(selected) => {
                                            return selected.id === item.id
                                          }}
                                          getOptionLabel={(option) => {
                                            return option.name
                                          }}
                                          value={item.id ? item : null}
                                          renderInput={(params) => (
                                            <TextField {...params} label={item.type} />
                                          )}
                                          onChange={(e, v) => {
                                            updateAssignedTo(v, index)
                                          }
                                          }
                                          autoSelect={true}
                                          autoComplete={true}
                                          autoHighlight={true}
                                          disableClearable
                                          ListboxProps={
                                            {
                                              style: {
                                                maxHeight: '120px',
                                              }
                                            }
                                          }
                                        />
                                      }
                                    </>
                                  )
                                  }
                                </div>
                              </div>
                              <Button
                                style={{ margin: '0px', padding: '7px', height: '32px', marginLeft: '10px' }}
                                color='danger'
                                title='Remove'
                                onClick={(e) => {
                                  e.preventDefault()
                                  removeAssignedTo(index)
                                }}
                              >
                                <Close style={{ margin: 0, padding: 0 }} />
                              </Button>
                            </div>
                          )
                        })}
                        <div style={{ width: '100%', textAlign: 'right' }}>
                          <Button
                            style={{ margin: '0px', padding: '7px', height: '32px' }}
                            color='primary'
                            title='Add'
                            onClick={(e) => {
                              e.preventDefault()
                              addNewAssignTo()
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            setTicketAssignedTo(assignedTo)
                          }}
                          disabled={assignedTo.find(i => i.id === null) !== undefined}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                  {(type === 'State') && (
                    <>
                      <Autocomplete
                        style={{ maxWidth: '350px' }}
                        disablePortal
                        options={ticketStates}
                        fullWidth
                        getOptionSelected={(selected) => {
                          return selected._id === ticketState._id
                        }}
                        getOptionLabel={(option) => {
                          return option.state
                        }}
                        value={ticketState}
                        renderInput={(params) => (
                          <TextField {...params} label={'State'} variant="outlined" />
                        )}
                        onChange={(e, v) => {
                          setTicketState(v)
                        }
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        // disableClearable
                        ListboxProps={
                          {
                            style: {
                              maxHeight: '80px',
                            }
                          }
                        }
                      />
                      <div style={{ textAlign: 'right', marginTop: '25px' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            updateTicketState(ticketState)
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                  {(type === 'Tags') && (
                    <>

                    </>
                  )}
                  {(type === 'Transfer') && (
                    <>
                      <Autocomplete
                        style={{ marginBottom: "10px", maxWidth: '350px' }}
                        disablePortal
                        options={selectableDepartments}
                        fullWidth
                        getOptionSelected={(selected) => {
                          return selected.id === department.id
                        }}
                        getOptionLabel={(option) => {
                          return option.name
                        }}
                        value={department}
                        renderInput={(params) => (
                          <TextField {...params} label={'Department'} />
                        )}
                        onChange={(e, v) => {
                          setDepartment(v)
                        }
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        disableClearable
                        ListboxProps={
                          {
                            style: {
                              maxHeight: '200px',
                            }
                          }
                        }
                      />
                      <span style={{ fontWeight: 400 }}>Internal Note:</span>
                      <br />
                      <ReactQuill
                        onChange={(content, delta, source, editor) => {
                          setText(editor.getHTML())
                        }}
                        value={text}
                        style={{ paddingBottom: "65px", height: '200px' }}
                        modules={modules}
                        formats={formats}
                      />
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            transferTicket(department, text)
                          }}
                          disabled={!department}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}

                  {(type === 'Users') && (
                    <>
                      <span >User:</span>
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "3px",
                          background: "#fafafa",
                          padding: "10px",
                          minWidth: '290px',
                          overflowX: 'auto',
                          marginBottom: '15px'
                        }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }}>
                            <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                              <Autocomplete
                                disablePortal
                                options={collaboratorTypes}
                                fullWidth
                                getOptionLabel={(option) =>
                                  option.label}
                                value={collaboratorTypes.find(i => i.value === user.type)}
                                renderInput={(params) => (
                                  <TextField {...params} label="Type" />
                                )}
                                ListboxProps={
                                  {
                                    style: {
                                      maxHeight: '120px',
                                    }
                                  }
                                }
                                onChange={(e, v) => {
                                  if (v !== user.type) {
                                    let entry = {
                                      id: null,
                                      name: null,
                                      type: v.value
                                    }
                                    setUser(entry)
                                  }
                                }
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                              />
                            </div>
                            <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                              {user.type === 'User' ?
                                <Autocomplete
                                  disablePortal
                                  options={filterOutExisting(users.concat(inactiveUsers), selectedUsers, user.type, user)}
                                  fullWidth
                                  getOptionSelected={(selected) => {
                                    return selected.id === user.id
                                  }}
                                  getOptionLabel={(option) => {
                                    return option.name
                                  }}
                                  value={user.id ? user : null}
                                  renderInput={(params) => (
                                    <TextField {...params} label={user.type} helperText={usersErrors.user} />
                                  )}
                                  onChange={(e, v) => {
                                    setUser(v)
                                  }
                                  }
                                  autoSelect={true}
                                  autoComplete={true}
                                  autoHighlight={true}
                                  disableClearable
                                  ListboxProps={
                                    {
                                      style: {
                                        maxHeight: '120px',
                                      }
                                    }
                                  }
                                />
                                :
                                <>
                                  <TextField
                                    fullWidth
                                    label='Email'
                                    onChange={(e) => {
                                      let val = {
                                        id: e.target.value,
                                        name: e.target.value,
                                        type: user.type
                                      }
                                      setUser(val)
                                    }}
                                    value={user.id ? user.id : ''}
                                    helperText={usersErrors.user}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <span>Collaborators:</span>
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "3px",
                          background: "#fafafa",
                          padding: "10px",
                          minWidth: '290px',
                          overflowX: 'auto',
                          marginBottom: '15px'
                        }}
                      >
                        {cc.map((item, index) => {
                          return (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }}>
                                <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                                  <Autocomplete
                                    disablePortal
                                    options={collaboratorTypes}
                                    fullWidth
                                    getOptionLabel={(option) =>
                                      option.label}
                                    value={collaboratorTypes.find(i => i.value === item.type)}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Type" />
                                    )}
                                    ListboxProps={
                                      {
                                        style: {
                                          maxHeight: '120px',
                                        }
                                      }
                                    }
                                    onChange={(e, v) => {
                                      if (v !== item.type) {
                                        let entry = {
                                          id: null,
                                          name: null,
                                          type: v.value
                                        }
                                        updateCC(entry, index)
                                      }
                                    }
                                    }
                                    autoSelect={true}
                                    autoComplete={true}
                                    autoHighlight={true}
                                    disableClearable
                                  />
                                </div>
                                <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                                  {item.type === 'User' ?
                                    <Autocomplete
                                      disablePortal
                                      options={filterOutExisting(users.concat(inactiveUsers), selectedUsers, item.type, item)}
                                      fullWidth
                                      getOptionSelected={(selected) => {
                                        return selected.id === item.id
                                      }}
                                      getOptionLabel={(option) => {
                                        return option.name
                                      }}
                                      value={item.id ? item : null}
                                      renderInput={(params) => (
                                        <TextField {...params} label={item.type} helperText={usersErrors.cc[index]} />
                                      )}
                                      onChange={(e, v) => {
                                        updateCC(v, index)
                                      }
                                      }
                                      autoSelect={true}
                                      autoComplete={true}
                                      autoHighlight={true}
                                      disableClearable
                                      ListboxProps={
                                        {
                                          style: {
                                            maxHeight: '120px',
                                          }
                                        }
                                      }
                                    />
                                    :
                                    <>
                                      <TextField
                                        fullWidth
                                        label='Email'
                                        onChange={(e) => {
                                          let val = {
                                            id: e.target.value,
                                            name: e.target.value,
                                            type: item.type
                                          }
                                          updateCC(val, index)
                                        }}
                                        value={item.id ? item.id : ''}
                                        helperText={usersErrors.cc[index]}
                                      />
                                    </>
                                  }
                                </div>
                              </div>
                              <Button
                                style={{ margin: '0px', padding: '7px', height: '32px', marginLeft: '10px' }}
                                color='danger'
                                title='Remove'
                                onClick={(e) => {
                                  e.preventDefault()
                                  removeCC(index)
                                }}
                              >
                                <Close style={{ margin: 0, padding: 0 }} />
                              </Button>
                            </div>
                          )
                        })}
                        <div style={{ width: '100%', textAlign: 'right' }}>
                          <Button
                            style={{ margin: '0px', padding: '7px', height: '32px' }}
                            color='primary'
                            title='Add'
                            onClick={(e) => {
                              e.preventDefault()
                              addNewCC()
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            //setTicketAssignedTo(assignedTo)
                            if (!validateNewUsers()) {
                              setTicketUsers()
                            }
                          }}
                          disabled={!hasCCChanged() && !hasUserChanged()}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}

                  {(type === 'Merge') && (
                    <>
                      <p style={{ fontWeight: 400 }}>Merge Following Tickets:</p>
                      {tickets.map((ticket, index) => {
                        return (
                          <p key={ticket.id}>{ticket.number}: {ticket.subject}</p>
                        )
                      })}
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            mergeTickets(tickets)
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}

                  {(type === 'Priority') && (
                    <>
                      <Autocomplete
                        style={{ marginBottom: "10px", maxWidth: '350px' }}
                        disablePortal
                        options={['normal', 'stale', 'medium', 'high', 'reset']}
                        fullWidth
                        value={priority}
                        renderInput={(params) => (
                          <TextField {...params} label={'Priority'} />
                        )}
                        onChange={(e, v) => {
                          setPriority(v)
                        }
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        disableClearable
                        ListboxProps={
                          {
                            style: {
                              maxHeight: '200px',
                            }
                          }
                        }
                      />
                      <span style={{ fontWeight: 400 }}>Internal Note:</span>
                      <br />
                      <ReactQuill
                        onChange={(content, delta, source, editor) => {
                          setText(editor.getHTML())
                        }}
                        value={text}
                        style={{ paddingBottom: "65px", height: '200px' }}
                        modules={modules}
                        formats={formats}
                      />
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            setTicketsPriority()
                          }}
                          disabled={tickets[0].priority === priority && tickets.length === 1}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}

                  {(type === 'NeedBy') && (
                    <>
                      <TextField
                        style={{ minWidth: '300px' }}
                        type="date"
                        variant="outlined"
                        label='Need By'
                        helperText={'*Leave blank for N/A'}
                        value={needBy}
                        onChange={(e) => {
                          setNeedBy(e.target.value)
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            setTicketsNeedBy()
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}

                  {(type === 'Location') && (
                    <>
                      <TextField
                        style={{ minWidth: '300px' }}
                        variant="outlined"
                        label='Location/Campus'
                        helperText={'*Leave blank for N/A'}
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value)
                        }}
                      />
                      <div style={{ textAlign: 'right' }}>
                        <Button color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            setTicketsLocation()
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}

                  {(type === 'Subject') && (
                    <>
                      <TextField
                        // style={{ minWidth: '300px' }}
                        variant="outlined"
                        label='Subject'
                        value={subject}
                        fullWidth
                        onChange={(e) => {
                          setSubject(e.target.value)
                        }}
                      />
                      <div style={{ textAlign: 'right', marginTop: '5px' }}>
                        <Button color="primary"
                          disabled={subject.trim() === ''}
                          onClick={(e) => {
                            e.preventDefault()
                            setTicketSubject()
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>

            </div>
          </div>,
          document.getElementById("file-list-modal")
        )
        }
      </React.Fragment >)}
    </>
  )
}


const TicketChangePopup = (props) => {
  return (
    <React.Suspense fallback={<></>}>
      <TicketChangePopupComp {...props} />
    </React.Suspense>
  )
}

export default TicketChangePopup