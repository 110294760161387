import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailOutline from "@material-ui/icons/MailOutline";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import api from "../../api.config";
import { newsfeedAtom } from "state/newsfeed";
import Loading from "components/Loading/Loading";

import Edit from "@material-ui/icons/Edit";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Close from '@material-ui/icons/Close';

import ReactTable from "components/ReactTable/ReactTable.js";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import { useRecoilValue } from "recoil";
import { TextField } from "@material-ui/core";


const useStyles = makeStyles(styles);

const NewsfeedComp = (props) => {
  const newsfeed = useRecoilValue(newsfeedAtom)
  const [editFeed, setEditFeed] = useState(null)
  const [togglePopup, setTogglePopup] = useState(false)

  const newsfeedList = newsfeed.map(item => {
    var index = item.id
    var newItem = {
      ...item,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={(e) => {
              setEditFeed(item)
              setTogglePopup(true)
            }}
            // data-tip
            // data-for={`edit-item-${index}`}
            title='Edit'
          >
            <Edit />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`edit-item-${index}`}
          >
            Edit
          </ReactTooltip> */}

          <Button
            justIcon
            color="danger"
            onClick={() => deleteItem(item.id)}
            // data-tip
            // data-for={`delete-item-${index}`}
            title='Delete'
          >
            <DeleteForeverIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`delete-item-${index}`}
          >
            delete
          </ReactTooltip> */}
        </React.Fragment>
      )
    }
    return newItem
  })

  const deleteItem = async (id) => {
    await axios
      .post(`${api.path}/staff_portal_api/v1/deletenewsfeed`, {
        id: id,

      })
      .then((res) => {
        return
      });
    window.location.reload()
  }

  const submitFormHandler = async (id, text, title) => {
    if (id !== null) {
      await axios
        .post(`${api.path}/staff_portal_api/v1/updatenewsfeed`, {
          feedjson: JSON.stringify({
            title: title,
            text: text,
          }),
          status: "publish",
          id: id,

        })
        .then((res) => {
          return
        });
    } else {
      await axios
        .post(`${api.path}/staff_portal_api/v1/createnewsfeed`, {
          feedjson: JSON.stringify({
            title: title,
            text: text,
          }),
          status: "publish",
        })
        .then((res) => {
          return
        });
    }
    window.location.reload()
  };



  return (
    <React.Fragment>
      <GridContainer>
        <GridItem
          style={{
            textAlign: "right",
          }}
          xs={12}
        >
          <Button
            color="primary"
            onClick={() => {
              setEditFeed(null)
              setTogglePopup(true)
            }}
            tabIndex="-100"
          >
            Create New
          </Button>
        </GridItem>
        <GridItem xs={12}>
          {togglePopup ?
            <NewfeedPopup newsfeed={editFeed} toggle={setTogglePopup} submit={submitFormHandler} />
            : <></>}
        </GridItem>
        <GridItem xs={12}>
          <ReactTable
            columns={[
              {
                Header: "Title",
                accessor: "title",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Updated",
                accessor: "updatedTime",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableFilters: false,
                disableSortBy: false,
              },
            ]}
            data={newsfeedList}
          />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};


const Newsfeed = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <NewsfeedComp {...props} />
    </React.Suspense>
  )
}

export default Newsfeed;

const NewfeedPopup = ({ newsfeed, toggle, submit }) => {
  const formats = [
    'bold',
    'color',
    'italic',
    'strike',
    'underline',
    'link',
    'blockquote',
    'header',
    'indent',
    'align',
    'direction',
  ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{
        'color': [
          '#99a3a4', '#7f8c8d', '#707b7c', '#616a6b', '#515a5a', '#424949', '#000000',
          '#e6b0aa', '#d98880', '#cd6155', '#c0392b', '#a93226', '#922b21', '#641e16',
          '#f5cba7', '#f0b27a', '#eb984e', '#e67e22', '#ca6f1e', '#af601a', '#935116',
          '#f7dc6f', '#f4d03f', '#f1c40f', '#d4ac0d', '#b7950b', '#9a7d0a', '#7d6608',
          '#82e0aa', '#58d68d', '#2ecc71', '#28b463', '#239b56', '#1d8348', '#186a3b',
          '#7fb3d5', '#5499c7', '#2980b9', '#2471a3', '#1f618d', '#1a5276', '#154360',
          '#bb8fce', '#a569bd', '#8e44ad', '#7d3c98', '#6c3483', '#5b2c6f', '#4a235a',
        ]
      }],
    ],
  }
  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      position: "absolute",
      top: "2vh",
      left: "calc(50% - 400px)",
      width: "800px",
      zIndex: 99999,
      maxHeight: "96vh",
      overflowY: "auto",
    },
  };

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
  };

  const textChangeHandler = (html) => {
    setText(html);
  };

  const classes = useStyles();
  const [title, setTitle] = useState(newsfeed ? newsfeed.title : '');
  const [text, setText] = useState(newsfeed ? newsfeed.body : '');

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={(e) => toggle(false)}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <GridContainer style={styles.modal}>
          <Card >
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <GridContainer style={{ alignItems: "center" }}>
                <GridItem xs={8}>
                  <h4 className={classes.cardIconTitle}>{newsfeed ? 'Edit' : 'Add'} Newsfeed Item</h4>
                </GridItem>
                <GridItem
                  xs={4}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    justIcon
                    round
                    color="danger"
                    size="sm"
                    onClick={(e) => toggle(false)}
                  >
                    <Close />
                  </Button>
                </GridItem>


              </GridContainer>
            </CardHeader>
            <CardBody>
              <form>
                <TextField
                  label="Title"
                  fullWidth
                  value={title}
                  onChange={(e) => titleChangeHandler(e)}
                />
                <label>Feed Text</label>
                <br />
                <ReactQuill
                  onChange={(content, delta, source, editor) => {
                    textChangeHandler(editor.getHTML())
                  }}
                  value={text}
                  style={{ paddingBottom: "30px", height: '300px' }}
                  modules={modules}
                  formats={formats}
                />
                <Button color="rose"
                  style={{ marginTop: '20px' }}
                  onClick={() => { submit(newsfeed ? newsfeed.id : null, text, title) }}
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridContainer>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  )
}