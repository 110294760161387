import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { userAtom } from "state/auth";
import { poListSubmittedByAtom } from "state/purchaseOrders";
import { isTSEnumMember } from "@babel/types";
import { useHistory } from "react-router-dom";
import {
  poListSubmittedToAtom,
  poListAdminViewAtom,
  poListAdminViewPendingReviewAtom,
  poListAdminViewNeedCheckAtom
} from "state/purchaseOrders";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CurrentPDF from "views/PurchaseOrders/CurrentPDF";
import { ArrowBack } from "@material-ui/icons";
import LoadingBars from "components/Loading/LoadingBars";
import update from "immutability-helper";
import api from "../../api.config";
import POBudgetWidget from "views/PurchaseOrders/POBudgetsAndCodes/POBudgetWidget";
import { decryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

const AdminActionBar = (props) => {
  const currentPo = props.currentPo
  const search = props.location.search ? decryptData(props.location.search.substring(1)) : null
  const returnLocation = search?.redirect?.split('_') || ''
  const showArchived = search?.showArchived || false
  const viewStatus = props.currentPo.status;

  const history = useHistory();

  const lineItems = currentPo && currentPo.lineItems ? JSON.parse(currentPo.lineItems) : [];

  const userData = useRecoilValue(userAtom);
  const [reason, setReason] = useState("");
  const [viewerType, setViewerType] = useState(
    currentPo.submittedBy === userData.email ? "viewer" : "approver"
  );

  const [showHideBudgetData, setShowHideBudgetData] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false);

  const getMatchingPos = () => {
    if (viewStatus === 'pending' || viewStatus.indexOf("awaiting") !== -1) {
      return useRecoilValue(poListAdminViewAtom).filter(
        (item) => item.status === 'pending' || item.status.indexOf("awaiting") !== -1)
    } else if (viewStatus === 'review') {
      return useRecoilValue(poListAdminViewAtom).filter(
        (item) => item.status === viewStatus)
    } else if (currentPo.paymentBy !== "check" && currentPo.status === "approved") {
      if (showArchived) {
        return useRecoilValue(poListAdminViewAtom).filter(
          (item) => item.paymentBy !== "check" &&
            item.status === "approved")
      } else {
        return useRecoilValue(poListAdminViewAtom).filter(
          (item) => item.paymentBy !== "check" &&
            item.status === "approved" && !item.archived)
      }
    } else if (currentPo.paymentBy === "check" && currentPo.checkProcessed !== 'yes' && currentPo.checkProcessed !== 'pending' && viewStatus === "approved") {
      if (showArchived) {
        return useRecoilValue(poListAdminViewNeedCheckAtom).filter(item => item.checkProcessed !== 'pending')
      } else {
        return useRecoilValue(poListAdminViewNeedCheckAtom).filter(item => item.checkProcessed !== 'pending' && !item.archived)
      }
    } else if (currentPo.paymentBy === "check" && currentPo.checkProcessed === 'pending' && viewStatus === "approved") {
      if (showArchived) {
        return useRecoilValue(poListAdminViewNeedCheckAtom).filter(item => item.checkProcessed === 'pending')
      } else {
        return useRecoilValue(poListAdminViewNeedCheckAtom).filter(item => item.checkProcessed === 'pending' && !item.archived)
      }
    } else if (currentPo.paymentBy === "check" && currentPo.checkProcessed === 'yes' && viewStatus === "approved") {
      if (showArchived) {
        return useRecoilValue(poListAdminViewAtom).filter(item => item.paymentBy === "check" && item.checkProcessed === 'yes' &&
          item.status === "approved")
      } else {
        return useRecoilValue(poListAdminViewAtom).filter(item => item.paymentBy === "check" && item.checkProcessed === 'yes' &&
          item.status === "approved" && !item.archived)
      }
    } else {
      return useRecoilValue(poListAdminViewAtom).filter(
        (item) => item.status === viewStatus)
    }
  }

  let allPurchaseOrders = getMatchingPos()


  /* if (viewerType === "viewer") {
    allPurchaseOrders = useRecoilValue(poListSubmittedByAtom).filter(
      (item) => item.status === viewStatus
    );
  } else {
    allPurchaseOrders = useRecoilValue(poListSubmittedToAtom).filter(
      (item) => item.status === viewStatus
    );
  } */

  //console.log("allPurchaseOrders", allPurchaseOrders);

  const getNextPo = () => {
    let next = allPurchaseOrders.findIndex(
      (item) => item.id === props.currentPo.id
    );

    if (next + 1 > allPurchaseOrders.length - 1) {
      return false;
    }

    if (props.location.search) {
      return `/portal/purchase-orders/admin/view/${allPurchaseOrders[next + 1].id}${props.location.search}`
    }
    return `/portal/purchase-orders/admin/view/${allPurchaseOrders[next + 1].id}`;
  };

  const getPrevPo = () => {
    let prev = allPurchaseOrders.findIndex(
      (item) => item.id === props.currentPo.id
    );

    if (prev - 1 < 0) {
      return false;
    }

    if (props.location.search) {
      return `/portal/purchase-orders/admin/view/${allPurchaseOrders[prev - 1].id}${props.location.search}`
    }
    return `/portal/purchase-orders/admin/view/${allPurchaseOrders[prev - 1].id}`;
  };

  const approveHandler = async () => {
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/acceptreject`, {
        auth: 'admin',
        status: 'approved',
        id: currentPo.id,
      })
      .then((res) => {
        /*  setCurrentPo((prevState) => {
          return update(prevState, {
            $merge: {
              status: "approved",
            },
          });
        });
        setButtonLoading(false);
        location.reload(); */
        location.href = returnLocation ? `/portal/purchase-orders/admin?tab=${returnLocation[1]}` : `/portal/purchase-orders/admin`;
      });
  };

  const reviewHandler = async () => {
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/acceptreject`, {
        auth: 'admin',
        status: 'reviewed',
        id: currentPo.id,
      })
      .then((res) => {
        location.href = returnLocation ? `/portal/purchase-orders/admin?tab=${returnLocation[1]}` : `/portal/purchase-orders/admin`;
      });
  };

  const rejectHandler = async () => {
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/acceptreject`, {
        auth: 'admin',
        status: 'reject',
        id: currentPo.id,
        reason: reason,
      })
      .then((res) => {
        location.href = returnLocation ? `/portal/purchase-orders/admin?tab=${returnLocation[1]}` : `/portal/purchase-orders/admin`;
      });
  };

  const reasonChangeHandler = (e) => {
    setReason(e.target.value);
  };

  const nextClickHandler = () => {
    let next = getNextPo();

    if (next) {
      //location.href = next;
      history.push(next);
    }
  };

  const prevClickHandler = () => {
    let prev = getPrevPo();

    if (prev) {
      //location.href = prev;
      history.push(prev);
    }
  };

  const voidClickHandler = async () => {
    //console.log("voiding PO", currentPo.id);
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/adminVoidbyid`, {
        data: {
          voidID: currentPo.id,
        },
      })
      .then((res) => (location.href = returnLocation ? `/portal/purchase-orders/admin?tab=${returnLocation[1]}` : `/portal/purchase-orders/admin`));
  };

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={11} md={8}>
          <Button href={returnLocation ? `/portal/purchase-orders/admin?tab=${returnLocation[1]}` : `/portal/purchase-orders/admin`} color="white">
            <ArrowBack /> {buttonLoading ? <LoadingBars /> : `Back`}
          </Button>
          <Button
            onClick={prevClickHandler}
            disabled={getPrevPo() === false ? true : false}
            color="primary"
          >
            {buttonLoading ? <LoadingBars /> : `Previous`}
          </Button>
          <Button
            onClick={nextClickHandler}
            disabled={getNextPo() === false ? true : false}
            color="primary"
          >
            {buttonLoading ? <LoadingBars /> : `Next`}
          </Button>

          <React.Fragment>
            <Button color="primary" onClick={() => setShowHideBudgetData(!showHideBudgetData)}>
              {buttonLoading ? <LoadingBars /> : showHideBudgetData ? 'Hide Budgets' : `Show Budgets`}
            </Button>
          </React.Fragment>

          {currentPo.status === "draft" && (
            <Button color="success" onClick={handleSubmitDraft}>
              {buttonLoading ? <LoadingBars /> : `Submit`}
            </Button>
          )}

          {currentPo.status === "approved" && (
            <PDFDownloadLink
              document={<CurrentPDF id={currentPo.id} currentPo={currentPo} />}
              fileName={`purchase_order_${currentPo.id}.pdf`}
              onClick={(e) => e.preventDefault()}
            >
              <Button color="warning">
                {buttonLoading ? <LoadingBars /> : `Download`}
              </Button>
            </PDFDownloadLink>
          )}

          {(currentPo.status !== "rejected") && (
            <React.Fragment>
              <Button color="danger" onClick={voidClickHandler}>
                {buttonLoading ? <LoadingBars /> : `Void`}
              </Button>
            </React.Fragment>
          )}

          <React.Fragment>
            {(currentPo.status === "pending" ||
              currentPo.status.indexOf("awaiting") !== -1) && (
                <React.Fragment>
                  <Button onClick={approveHandler} color="success">
                    {buttonLoading ? <LoadingBars /> : `Approve`}
                  </Button>
                  <Button onClick={rejectHandler} color="danger">
                    {buttonLoading ? <LoadingBars /> : `Reject`}
                  </Button>
                  <TextField
                    label="Reason for rejection"
                    variant="outlined"
                    fullWidth
                    value={reason}
                    onChange={reasonChangeHandler}
                  />
                </React.Fragment>
              )}
          </React.Fragment>

          <React.Fragment>
            {(currentPo.status === "review") && (
              <React.Fragment>
                <Button onClick={reviewHandler} color="success">
                  {buttonLoading ? <LoadingBars /> : `Reviewed`}
                </Button>
                <Button onClick={approveHandler} color="success">
                  {buttonLoading ? <LoadingBars /> : `Approve`}
                </Button>
                <Button onClick={rejectHandler} color="danger">
                  {buttonLoading ? <LoadingBars /> : `Reject`}
                </Button>
                <TextField
                  label="Reason for rejection"
                  variant="outlined"
                  fullWidth
                  value={reason}
                  onChange={reasonChangeHandler}
                />
              </React.Fragment>
            )}

          </React.Fragment>


        </GridItem>

      </GridContainer>
      {currentPo.adminOverride && (
        <GridContainer>
          <GridItem xs={12}>
            <p style={{ color: 'red' }}>{currentPo.adminOverride === 'yes' ? 'Admin Overides: Yes' : currentPo.adminOverride}</p>
          </GridItem>
        </GridContainer>)}
      {/* <GridContainer>
        <GridItem>
          <POBudgetWidget lineItems={lineItems} isAdmin={true} />
        </GridItem>
      </GridContainer> */}
    </React.Fragment>
  );
};

export default AdminActionBar;
