import React, { useMemo, useRef, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import "./viewTicket.css"

import update from "immutability-helper";
import DOMPurify from "dompurify";
import { decryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

import {
  AttachFile,
  Close,
  Flag,
  MergeType,
  DoubleArrow,
  AssignmentInd,
  AssignmentLate,
  People,
  Add,
  Room,
  Today,
  LocalOffer,
  Category,
  Title
} from "@material-ui/icons";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GoBack from "components/GoBack/GoBack";

import {
  viewTicketAtom,
  selectableDepartmentsAtom,
  departmentSelectableOptionsAtom,
  newTicketMessage,
  departmentTicketStatesAtom
} from "state/ticketSystem";
import TicketChangePopup from "components/TicketSystem/TicketChangePopup";
import { getTicket } from "state/ticketSystem";

import blankProfile from 'assets/img/blankProfile.png'
import { userAtom } from "state/auth";

const ViewTicketComp = (props) => {

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "indent",
    "link",
    "color",
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
        ],
      },
      clipboard: {
        matchVisual: true,
      },
    })
  );

  const userType = props.match.params.userType
  const id = props.match.params.id
  const search = props.location.search?.split('?')[1] || null

  const user = useRecoilValue(userAtom)

  const [ticket, setTicket] = useRecoilState(viewTicketAtom({ id: id, requestAs: userType }))
  const [optionType, setOptionType] = useState('')
  const [loading, setLoading] = useState(false)
  const [messageLoading, setMessageLoading] = useState(false)

  const replyOptions = [
    {
      label: 'Reply',
      value: 'reply'
    },
    {
      label: 'Internal Note',
      value: 'internal'
    }
  ]

  const clientReply = [
    {
      label: 'Client Reply',
      value: 'client'
    }
  ]

  const statusOptions = ['Open', 'Closed']

  const canViewAll = userType === 'admin' || userType === 'agent' ? true : false

  const quill = useRef();

  const maxUploadSize = 200000000 // 200MB
  const [text, setText] = useState('');
  const [files, setFiles] = useState([])
  const [replyType, setReplyType] = useState(canViewAll ? replyOptions[0] : clientReply[0])
  const [tags, setTags] = useState(canViewAll ? ticket ? ticket.tags : [] : [])
  const [tag, setTag] = useState('')
  const [ticketStatus, setTicketStatus] = useState(ticket ? ticket.status : null)

  const totalUploadSize = files.map(i => i.size).reduce((a, b) => a + b, 0)

  const selectableDepartments = canViewAll ? useRecoilValue(selectableDepartmentsAtom(true)) : null
  const departmentSelectableOptions = canViewAll ? ticket ? useRecoilValue(departmentSelectableOptionsAtom(ticket.department.id)) : null : null
  const departmentTicketStates = canViewAll ? ticket ? useRecoilValue(departmentTicketStatesAtom(ticket.department.id)) : [] : []
  const [ticketState, setTicketState] = useState(departmentTicketStates.length > 0 ? departmentTicketStates.find(t => t.state === ticket.state) ? departmentTicketStates.find(t => t.state === ticket.state) : null : null)
  const handleTicketChangeUpdate = async () => {
    let t = await getTicket({ id: id, requestAs: userType })
    if (t) {
      setTicket(t)
      setText('')
      setFiles([])
      setLoading(false)
      setMessageLoading(false)
    } else {
      window.location.href = getReturnLocation()
    }
  }

  const getReturnLocation = () => {
    let returnLoc = decryptData(search)?.redirect || null
    if (returnLoc) {
      return returnLoc
    } else if (userType === 'agent' || userType === 'admin') {
      return '/portal/ticketSystem/departmentTickets'
    } else if (userType === 'user') {
      return '/portal/ticketSystem/myTickets'
    }

    return null
  }
  const styles = {
    topbarButtons: { height: '25px', width: '25px' },
    ticketInfoItem: { display: 'flex', alignItems: 'center', marginBottom: '5px', flexWrap: 'wrap' },
    clientMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#feddbc', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    replyMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#6babd5', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    internalMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#c5e2f4', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' }
  }

  const submitTicketMessage = async () => {
    setMessageLoading(true)
    let formData = new FormData()
    let attachments = null
    formData.append('msgType', replyType.value)
    formData.append('html', text)
    if (ticket.status !== ticketStatus) {
      formData.append('newStatus', ticketStatus)
    }
    if (files.length > 0) {
      attachments = files
    }
    if (canViewAll) {
      if (tags.length !== 0) {
        tags.forEach(t => {
          formData.append('newTags', t)
        });
      } else {
        formData.append('newTags', ' ')
      }

      if (departmentTicketStates.length > 0) {
        if (ticketState) {
          formData.append('newState', ticketState.state)
        } else {
          formData.append('newState', ' ')
        }
      }
    }
    formData.append('ticketID', ticket.id)
    await newTicketMessage(formData, attachments)
    handleTicketChangeUpdate()
  }
  return (
    <React.Fragment>
      {ticket ? <>
        {loading ? <GridContainer><GridItem xs={12}><Loading color="blue" /></GridItem></GridContainer> : <>
          {(canViewAll) && (
            <TicketChangePopup
              type={optionType}
              setType={setOptionType}
              tickets={[ticket]}
              updateData={handleTicketChangeUpdate}
              setLoading={setLoading}
              selectableDepartments={selectableDepartments.filter(i => i.id !== ticket.department.id)}
              departmentSelectableOptions={departmentSelectableOptions}
              ticketStates={departmentTicketStates}
            />)}
          <GridContainer>
            <GridItem xs={12} style={{ maxWidth: '1100px', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <GoBack disableRound={true} returnLocation={getReturnLocation()} />
                  <p style={{ fontWeight: 600, fontSize: '28px', margin: '0px 10px' }}>Ticket #{ticket.number}</p>
                </div>

                {canViewAll && (
                  <div style={{ display: 'flex' }}>
                    <Button
                      justIcon
                      color='primary'
                      title='Status'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Status')
                      }}
                    >
                      <Flag style={styles.topbarButtons} />
                    </Button>
                    <Button
                      justIcon
                      color='primary'
                      title='Subject'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Subject')
                      }}
                    >
                      <Title style={styles.topbarButtons} />
                    </Button>
                    {/* <Button
                      justIcon
                      color='primary'
                      title='Tags'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Tags')
                      }}
                    >
                      <LocalOffer style={{ height: '25px', width: '25px' }} />
                    </Button> */}
                    {(departmentTicketStates.length > 0) && (
                      <Button
                        justIcon
                        color='primary'
                        title='State'
                        style={{ margin: '3px' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setOptionType('State')
                        }}
                      >
                        <Category style={{ height: '25px', width: '25px' }} />
                      </Button>)}
                    <Button
                      justIcon
                      color='primary'
                      title='Assign'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Assign')
                      }}
                    >
                      <AssignmentInd style={styles.topbarButtons} />
                    </Button>
                    <Button
                      justIcon
                      color='primary'
                      title='Priority'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Priority')
                      }}
                    >
                      <AssignmentLate style={styles.topbarButtons} />
                    </Button>
                    <Button
                      justIcon
                      color='primary'
                      title='Need By'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('NeedBy')
                      }}
                    >
                      <Today style={styles.topbarButtons} />
                    </Button>
                    <Button
                      justIcon
                      color='primary'
                      title='Location'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Location')
                      }}
                    >
                      <Room style={styles.topbarButtons} />
                    </Button>
                    <Button
                      justIcon
                      color='primary'
                      title='Users'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Users')
                      }}
                    >
                      <People style={styles.topbarButtons} />
                    </Button>
                    <Button
                      justIcon
                      color='primary'
                      title='Transfer'
                      style={{ margin: '3px' }}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Transfer')
                      }}
                    >
                      <DoubleArrow style={styles.topbarButtons} />
                    </Button>
                  </div>)}
              </div>
              <div>
                <Card style={{ margin: '0px', marginBottom: '10px' }}>
                  <CardBody>
                    <GridContainer
                    >
                      <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                        <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Created:</span>
                        <span>{ticket.created}</span>
                      </GridItem>
                      <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                        <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>User:</span>
                        <div
                          style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                          onClick={(e) => {
                            e.preventDefault()
                            if (canViewAll) {
                              setOptionType('Users')
                            }
                          }}
                          title={[ticket.user.name ? `${ticket.user.name} (${ticket.user.email})` : ticket.user.email].concat(ticket.collaborators.map(i => {
                            if (i.name) {
                              return `${i.name} (${i.email})`
                            } else {
                              return i.email
                            }
                          })).join('\n')}
                        >
                          <span style={{ marginRight: '5px' }}>{ticket.user.name ? ticket.user.name : ticket.user.email}</span>
                          {ticket.collaborators.length > 0 && (
                            <div
                              style={{ color: '#389ada', display: 'flex', alignItems: 'center' }}
                            >
                              <People />
                              <span>({ticket.collaborators.length})</span>
                            </div>
                          )}
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                        <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Status:</span>
                        <span>{ticket.status}</span>
                      </GridItem>
                      {!canViewAll && (
                        <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                          <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Department:</span>
                          <span style={{ cursor: 'pointer' }} title={ticket.department.email}>{ticket.department.name}</span>
                        </GridItem>
                      )}
                      {canViewAll && (
                        <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                          <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Priority:</span>
                          <span>{ticket.priority ? ticket.priority : 'normal'}</span>
                        </GridItem>
                      )}
                      <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                        <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Location:</span>
                        <span>{ticket.location ? ticket.location : 'N/A'}</span>
                      </GridItem>
                      <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                        <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Need By:</span>
                        <span>{ticket.needBy ? ticket.needBy : 'N/A'}</span>
                      </GridItem>
                      {canViewAll && (
                        <>
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Department:</span>
                            <span style={{ cursor: 'pointer' }} title={ticket.department.email}>{ticket.department.name}</span>
                          </GridItem>

                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Assign To:</span>
                            <div
                              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                              onClick={(e) => {
                                e.preventDefault()
                                if (canViewAll) {
                                  setOptionType('Assign')
                                }
                              }}
                              title={ticket.assignedTo.map(i => {
                                if (i.type === 'User') {
                                  return `${i.name} (${i.email})`
                                } else {
                                  return i.name
                                }
                              }).join('\n')}>
                              <span
                                style={{ marginRight: '5px' }}
                              >
                                {ticket.assignedTo[0].name}
                              </span>
                              {ticket.assignedTo.length > 1 && (
                                <div
                                  style={{ color: '#389ada', display: 'flex', alignItems: 'center' }}
                                >
                                  <People />
                                  <span>({ticket.assignedTo.length})</span>
                                </div>
                              )}
                            </div>
                          </GridItem>
                          {(departmentTicketStates.length > 0) && (
                            <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                              <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>State:</span>
                              <span style={{ cursor: 'pointer' }} title={ticket.department.email}>{ticket.state ? ticket.state : 'N/A'}</span>
                            </GridItem>)}
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Last Update:</span>
                            <span>{ticket.lastMessage}</span>
                          </GridItem>
                          <GridItem xs={12} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Tags:</span>
                            <span style={{ cursor: 'pointer' }} title={ticket.department.email}>{ticket.tags.length > 0 ? ticket.tags.join(', ') : 'N/A'}</span>
                          </GridItem>
                        </>
                      )}
                    </GridContainer>
                  </CardBody>
                </Card>
                <p style={{ fontWeight: 600, fontSize: '18px' }}>{ticket.subject}</p>
                <div>
                  {ticket.messages.map(message => {
                    let elements = []

                    if (message.type === 'merge') {
                      elements.push(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                            <MergeType />
                          </Button>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <span style={{ marginRight: '10px' }}>{message.created}:</span>
                            <span>
                              <strong>
                                {message.user.name ? message.user.name : message.user.email}</strong> merged tickets</span>
                            {message.otherTicketID.map((other, index) => {
                              return (
                                <span key={other.id}>
                                  <a
                                    style={{ marginLeft: '3px', cursor: 'pointer', color: '#3695d7', textDecoration: 'none' }}
                                    href={`/portal/ticketSystem/view/${userType}/${other.id}`}
                                    target="_blank"
                                  >
                                    #{other.number}
                                  </a>
                                  {index !== message.otherTicketID.length - 1 && (<span>,</span>)}
                                </span>
                              )
                            })}
                            <span>.</span>
                          </div>
                        </div>
                      )
                    }

                    if (message.type === 'transfer') {
                      elements.push(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                            <DoubleArrow />
                          </Button>
                          <div>
                            <span style={{ marginRight: '10px' }}>{message.created}:</span>
                            <span>
                              <strong>{message.user.name ? message.user.name : message.user.email}</strong> transfered ticket to {message.newDepartmentID.name}.
                            </span>
                          </div>
                        </div>
                      )
                    }

                    if (message.type === 'status') {
                      elements.push(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                            <Flag />
                          </Button>
                          <div>
                            <span style={{ marginRight: '10px' }}>{message.created}:</span>
                            <span>
                              <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed ticket status to {message.newStatus}.
                            </span>
                          </div>
                        </div>
                      )
                    }

                    if (message.type === 'subject') {
                      elements.push(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                            <Title />
                          </Button>
                          <div>
                            <span style={{ marginRight: '10px' }}>{message.created}:</span>
                            <span>
                              <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed ticket subject to {message.subject}.
                            </span>
                          </div>
                        </div>
                      )
                    }

                    if (message.type === 'collaborators') {
                      if (message.newTicketOwner) {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <People />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the ticket Owner to <strong>{message.newTicketOwner.name ? message.newTicketOwner.name : message.newTicketOwner.email}</strong>.
                              </span>
                            </div>
                          </div>
                        )
                      }
                      if (message.newCollaborators !== null) {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <People />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              {message.newCollaborators.length === 0 ?
                                <span>
                                  <strong>{message.user.name ? message.user.name : message.user.email}</strong> removed all ticket Collaborators.
                                </span>
                                :
                                <span>
                                  <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the ticket Collaborators to <strong>{message.newCollaborators.map(c => c.name ? c.name : c.email).join(', ')}</strong>.
                                </span>
                              }
                            </div>
                          </div>
                        )
                      }
                    }

                    if (canViewAll) {

                      if (message.type === 'location') {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <Room />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed location to {message.location ? message.location : 'N/A'}.
                              </span>
                            </div>
                          </div>
                        )
                      }

                      if (message.type === 'needBy') {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <Today />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed need by to {message.needBy ? message.needBy : 'N/A'}.
                              </span>
                            </div>
                          </div>
                        )
                      }

                      if (message.type === 'assignTo') {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <AssignmentInd />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                <strong>{message.user.name ? message.user.name : message.user.email}</strong> assigned ticket to {message.assignTo.map(i => i.name).join(', ')}.
                              </span>
                            </div>
                          </div>
                        )
                      }

                      if (message.type === 'priority') {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <AssignmentLate />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                {message.priority === 'reset' ? <>
                                  <strong>{message.user.name ? message.user.name : message.user.email}</strong> has reset the tickets priority.
                                </> : <>
                                  <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the priority to {message.priority}.
                                </>}
                              </span>
                            </div>
                          </div>
                        )
                      }

                      if (message.type === 'state') {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <Category />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                {message.newState === null ?
                                  <>
                                    <strong>{message.user.name ? message.user.name : message.user.email}</strong> cleared the state.
                                  </>
                                  :
                                  <>
                                    <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the state to {message.newState}.
                                  </>
                                }
                              </span>
                            </div>
                          </div>
                        )
                      }

                      if (message.type === 'tags') {
                        elements.push(
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                              <LocalOffer />
                            </Button>
                            <div>
                              <span style={{ marginRight: '10px' }}>{message.created}:</span>
                              <span>
                                {message.newTags.length === 0 ?
                                  <>
                                    <strong>{message.user.name ? message.user.name : message.user.email}</strong> removed all tags.
                                  </>
                                  :
                                  <>
                                    <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the tags to {message.newTags.join(', ')}.
                                  </>
                                }
                              </span>
                            </div>
                          </div>
                        )
                      }

                      if (message.type !== 'reply' && message.type !== 'client' && (message.html || message.text)) {
                        elements.push(
                          <>
                            <Card style={{ margin: '0px' }}>
                              <div style={styles.internalMessage}>
                                <div style={{ display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                                  <img
                                    src={message.user.avatar ? message.user.avatar : blankProfile}
                                    className="profileImageLeft"
                                  />
                                  <span style={{ lineBreak: 'anywhere', marginLeft: '10px' }}>{message.user.name ? message.user.name : message.user.email}</span>
                                </div>
                                <span style={{ marginRight: '10px' }}>{message.created}</span>
                              </div>
                              <CardBody style={{ overflow: 'auto' }}>
                                {message.html ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.html) }} /> : <p>{message.text}</p>}
                              </CardBody>
                            </Card>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {(message.files).map((file, index) => {
                                return (
                                  <a
                                    key={index}
                                    href={file.file}
                                    target="_blank"
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <Button
                                      style={{
                                        width: '200px',
                                        height: '50px',
                                        backgroundColor: '#fff',
                                        borderRadius: '5px',
                                        margin: '10px 10px 0px 0px',
                                        display: 'flex',
                                        justifyContent: 'start',
                                        padding: '5px',
                                        color: 'black',
                                        overflow: 'hidden',
                                        alignItems: 'center'
                                      }}
                                      title={file.fileName}
                                    >
                                      <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px' }} />
                                      <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.fileName.length > 30 ? `${file.fileName.substring(0, 27)}...` : file.fileName}</span>
                                    </Button>
                                  </a>
                                )
                              })}
                            </div>
                          </>
                        )
                      }
                    }

                    if (message.type === 'reply') {
                      elements.push(
                        <>
                          <Card style={{ margin: '0px' }}>
                            <div style={styles.replyMessage}>
                              <div style={{ display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                                <img
                                  src={message.user.avatar ? message.user.avatar : blankProfile}
                                  className="profileImageLeft"
                                />
                                <span style={{ lineBreak: 'anywhere', marginLeft: '10px' }}>{message.user.name ? message.user.name : message.user.email}</span>
                              </div>
                              <span style={{ marginRight: '10px' }}>{message.created}</span>
                            </div>
                            <CardBody style={{ overflow: 'auto' }}>
                              {message.html ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.html) }} /> :
                                <>
                                  {message.text.split('\n').map((line, index) => {
                                    return (
                                      <>
                                        <span style={{ margin: '0px' }} key={index}>{line}</span>
                                        {message.text.split('\n').length - 1 !== index && (<br />)}
                                      </>
                                    )
                                  })}
                                </>
                              }
                            </CardBody>
                          </Card>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {(message.files).map((file, index) => {
                              return (
                                <a
                                  key={index}
                                  href={file.file}
                                  target="_blank"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Button
                                    style={{
                                      width: '200px',
                                      height: '50px',
                                      backgroundColor: '#fff',
                                      borderRadius: '5px',
                                      margin: '10px 10px 0px 0px',
                                      display: 'flex',
                                      justifyContent: 'start',
                                      padding: '5px',
                                      color: 'black',
                                      overflow: 'hidden',
                                      alignItems: 'center'
                                    }}
                                    title={file.fileName}
                                  >
                                    <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px' }} />
                                    <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.fileName.length > 30 ? `${file.fileName.substring(0, 27)}...` : file.fileName}</span>
                                  </Button>
                                </a>
                              )
                            })}
                          </div>
                        </>
                      )
                    }
                    if (message.type === 'client') {
                      elements.push(
                        <>
                          <Card style={{ margin: '0px' }}>
                            <div style={styles.clientMessage}>
                              <span style={{ marginLeft: '10px' }}>{message.created}</span>
                              <div style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px', lineBreak: 'anywhere' }}>{message.user.name ? message.user.name : message.user.email}</span>

                                <img
                                  src={message.user.avatar ? message.user.avatar : blankProfile}
                                  className="profileImageRight"
                                />
                              </div>
                            </div>
                            <CardBody style={{ overflow: 'auto' }}>
                              {message.html ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.html) }} /> : <p>{message.text}</p>}
                            </CardBody>
                          </Card>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {(message.files).map((file, index) => {
                              return (
                                <a
                                  key={index}
                                  href={file.file}
                                  target="_blank"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Button
                                    style={{
                                      width: '200px',
                                      height: '50px',
                                      backgroundColor: '#fff',
                                      borderRadius: '5px',
                                      margin: '10px 10px 0px 0px',
                                      display: 'flex',
                                      justifyContent: 'start',
                                      padding: '5px',
                                      color: 'black',
                                      overflow: 'hidden',
                                      alignItems: 'center',
                                    }}
                                    title={file.fileName}
                                  >
                                    <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px' }} />
                                    <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.fileName.length > 30 ? `${file.fileName.substring(0, 27)}...` : file.fileName}</span>
                                  </Button>
                                </a>
                              )
                            })}
                          </div>
                        </>
                      )
                    }

                    return (
                      <div key={message.id}>
                        {elements.map((i, index) => {
                          return (
                            <div key={index}>
                              {i}
                              <div style={{ display: 'flex', flexDirection: 'column', width: '41px', alignItems: 'center', margin: '6px 0px' }}>
                                <div style={{ borderLeft: '2px solid #ccc', height: '15px' }} />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )

                  })}
                  {ticket.merged === null ?
                    <Card style={{ margin: '0px' }}>
                      <div style={replyType.value === 'client' ? styles.clientMessage : replyType.value === 'reply' ? styles.replyMessage : styles.internalMessage}>
                        {replyType.value !== 'client' && (
                          <div>
                            <img
                              src={user.avatar ? user.avatar : blankProfile}
                              className="profileImageLeft"
                              style={{ marginRight: '10px' }}
                            />
                            <span>{user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}</span>
                          </div>
                        )}
                        {replyType.value === 'client' && (
                          <>
                            <div></div>
                            <div>
                              <span>{user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}</span>
                              <img
                                src={user.avatar ? user.avatar : blankProfile}
                                className="profileImageRight"
                                style={{ marginLeft: '10px' }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <CardBody>
                        {messageLoading ? <Loading color='blue' /> : <>
                          {(replyType.value === 'internal' || replyType.value === 'reply') && (
                            <>
                              <Autocomplete
                                disablePortal
                                options={replyOptions}
                                value={replyType}
                                getOptionSelected={(opt) => opt.value === replyType.value}
                                getOptionLabel={(option) =>
                                  option.label}
                                renderInput={(params) => (
                                  <TextField {...params} label="Type" />
                                )}
                                style={{ maxWidth: '300px', marginBottom: '10px' }}
                                ListboxProps={
                                  {
                                    style: {
                                      maxHeight: '120px',
                                    }
                                  }
                                }
                                onChange={(e, v) => { setReplyType(v) }}
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                              />
                              <Autocomplete
                                disablePortal
                                options={statusOptions}
                                value={ticketStatus}
                                renderInput={(params) => (
                                  <TextField {...params} label="Status" />
                                )}
                                style={{ maxWidth: '300px', marginBottom: '10px' }}
                                ListboxProps={
                                  {
                                    style: {
                                      maxHeight: '120px',
                                    }
                                  }
                                }
                                onChange={(e, v) => { setTicketStatus(v) }}
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                              />
                            </>
                          )}
                          {canViewAll && (
                            <>
                              {(departmentTicketStates.length > 0) && (
                                <Autocomplete
                                  style={{ maxWidth: '300px', marginBottom: '10px' }}
                                  disablePortal
                                  options={departmentTicketStates}
                                  fullWidth
                                  getOptionSelected={(selected) => {
                                    return selected._id === ticketState._id
                                  }}
                                  getOptionLabel={(option) => {
                                    return option.state
                                  }}
                                  value={ticketState}
                                  renderInput={(params) => (
                                    <TextField {...params} label={'State'} />
                                  )}
                                  onChange={(e, v) => {
                                    setTicketState(v)
                                  }
                                  }
                                  autoSelect={true}
                                  autoComplete={true}
                                  autoHighlight={true}
                                  // disableClearable
                                  ListboxProps={
                                    {
                                      style: {
                                        maxHeight: '80px',
                                      }
                                    }
                                  }
                                />)}
                              <div style={{ marginBottom: '10px' }}>
                                <p style={{ margin: 0, padding: 0 }}>Tags:</p>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  <TextField
                                    style={{ width: '100px', marginRight: '5px' }}
                                    placeholder="tag"
                                    value={tag}
                                    onChange={(e) => {
                                      setTag(e.target.value)
                                    }}
                                  />
                                  <Button
                                    style={{
                                      height: '30px',
                                      borderRadius: '5px',
                                      margin: '5px 10px 0px 0px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: '0px',
                                      overflow: 'hidden',
                                      alignItems: 'center',
                                    }}
                                    justIcon
                                    title='Upload'
                                    color='primary'
                                    component="span"
                                    variant="contained"
                                    disabled={tag.trim() === ''}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      if (tags.find(i => i.toLowerCase() === tag.toLowerCase()) === undefined || tags.length === 0) {
                                        setTags((prevState) => {
                                          return update(prevState, {
                                            $push: [tag.trim().toLowerCase()]
                                          })
                                        })
                                      } else {
                                        alert(`Tag: ${tag} already in list.`)
                                      }
                                      setTag('')
                                    }}
                                  >
                                    <Add style={{ margin: '0px', padding: '0px' }} />
                                  </Button>
                                  {tags.map((tag, index) => {
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          margin: '5px 10px 0px 0px',
                                          alignItems: 'center',
                                          backgroundColor: '#eee',
                                          borderRadius: '5px'
                                        }}
                                        key={index}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            padding: '0px 5px',
                                            color: 'black',
                                            overflow: 'hidden',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{tag}</span>
                                        </div>
                                        <Close
                                          style={{ margin: '0px', padding: '0px', color: '#333', cursor: 'pointer', fontSize: '20px', color: '#3695d7' }}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            setTags((prevState) => {
                                              return update(prevState, {
                                                $splice: [[index, 1]]
                                              })
                                            })
                                          }}
                                        />
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                          <ReactQuill
                            ref={(el) => (quill.current = el)}
                            onChange={(content, delta, source, editor) => {
                              setText(editor.getHTML())
                            }}
                            preserveWhitespace
                            value={text}
                            style={{ marginBottom: '5px' }}
                            modules={modules}
                            formats={formats}
                          />
                          <>
                            <p style={{ margin: 0, padding: 0 }}>Attachments:</p>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <input
                                style={{ display: 'none' }}
                                id="upload-button"
                                multiple
                                type="file"
                                onChange={(e) => {
                                  let files = []
                                  for (let i = 0; i < e.target.files.length; i++) {
                                    files.push(e.target.files[i])
                                  }
                                  setFiles((prevState) => {
                                    return update(prevState, {
                                      $push: files
                                    })
                                  })
                                }}
                              />
                              <label htmlFor="upload-button">
                                <Button
                                  style={{
                                    height: '30px',
                                    borderRadius: '5px',
                                    margin: '5px 10px 0px 0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '0px',
                                    overflow: 'hidden',
                                    alignItems: 'center',
                                  }}
                                  justIcon
                                  title='Upload'
                                  color='primary'
                                  component="span"
                                  variant="contained"
                                >
                                  <Add style={{ margin: '0px', padding: '0px' }} />
                                </Button>
                              </label>
                              {files.map((file, index) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      margin: '5px 10px 0px 0px',
                                      alignItems: 'center',
                                      backgroundColor: '#eee',
                                      borderRadius: '5px'
                                    }}
                                    key={index}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        padding: '0px 5px',
                                        color: 'black',
                                        overflow: 'hidden',
                                        alignItems: 'center',
                                      }}
                                      title={file.name}
                                    >
                                      <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px', fontSize: '18px' }} />
                                      <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.name}</span>
                                    </div>
                                    <Button
                                      style={{ padding: '5px', margin: '0px' }} color='primary'
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setFiles((prevState) => {
                                          return update(prevState, {
                                            $splice: [[index, 1]]
                                          })
                                        })
                                      }}
                                    >
                                      <Close style={{ margin: '0px', padding: '0px', }} />
                                    </Button>
                                  </div>
                                )
                              })}
                            </div>
                            <p style={{ marginTop: '5px', color: totalUploadSize > maxUploadSize ? 'red' : 'black' }}>Upload Size: {Math.round((totalUploadSize / 1000000) * 100) / 100} / {maxUploadSize / 1000000}MB</p>
                          </>
                          <div style={{ textAlign: 'right' }}>
                            <Button color="primary"
                              onClick={(e) => {
                                e.preventDefault()
                                submitTicketMessage()
                              }}
                              disabled={text.trim() === '' || !replyType || !ticketStatus || totalUploadSize > maxUploadSize}
                            >
                              Send
                            </Button>
                          </div>
                        </>}
                      </CardBody>
                    </Card>
                    :
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                        <MergeType />
                      </Button>
                      <div>
                        <span style={{ marginRight: '10px' }}>{ticket.lastMessage}:</span>
                        <span>
                          Ticket was merged into ticket <a
                            style={{ marginLeft: '3px', cursor: 'pointer', color: '#3695d7', textDecoration: 'none' }}
                            href={`/portal/ticketSystem/view/${userType}/${ticket.merged.id}`}
                            target="_blank"
                          >
                            #{ticket.merged.number}
                          </a>
                        </span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </>}
      </>
        :
        <div>
          <GoBack disableRound={true} returnLocation={getReturnLocation()} />
          <span>You don't have access to view this ticket.</span>
        </div>
      }
    </React.Fragment >
  )
}

const ViewTicket = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ViewTicketComp {...props} />
    </React.Suspense>
  )
}

export default ViewTicket