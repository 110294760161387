import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import api from "../../api.config";
import Loading from "components/Loading/Loading";

import { userAtom } from "state/auth";

import { useRecoilValue } from "recoil";

import update from 'immutability-helper';
import { TextField } from "@material-ui/core";
import { lastMyMinistryFormAtom } from "state/HBABFormRequest";

const MyMinistryComponent = (props) => {
  const user = useRecoilValue(userAtom)
  const lastForm = useRecoilValue(lastMyMinistryFormAtom)

  const formats = [
    'bold',
    'color',
    'italic',
    'strike',
    'underline',
    'link',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
  ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ list: "ordered" }],
      [{
        'color': [
          '#99a3a4', '#7f8c8d', '#707b7c', '#616a6b', '#515a5a', '#424949', '#000000',
          '#e6b0aa', '#d98880', '#cd6155', '#c0392b', '#a93226', '#922b21', '#641e16',
          '#f5cba7', '#f0b27a', '#eb984e', '#e67e22', '#ca6f1e', '#af601a', '#935116',
          '#f7dc6f', '#f4d03f', '#f1c40f', '#d4ac0d', '#b7950b', '#9a7d0a', '#7d6608',
          '#82e0aa', '#58d68d', '#2ecc71', '#28b463', '#239b56', '#1d8348', '#186a3b',
          '#7fb3d5', '#5499c7', '#2980b9', '#2471a3', '#1f618d', '#1a5276', '#154360',
          '#bb8fce', '#a569bd', '#8e44ad', '#7d3c98', '#6c3483', '#5b2c6f', '#4a235a',
        ]
      }],
    ],
  }

  const emptyForm = {
    id: null,
    dateCreated: null,
    name: '',
    email: '',
    ministry: '',
    directReport: '',
    coreValues: {
      html: '',
      content: null
    },
    report: {
      html: '',
      content: null
    },
    orgMap: {
      html: '',
      content: null
    },
    ministryArea: {
      html: '',
      content: null
    },
  }

  const [formInfo, setFormInfo] = useState(lastForm !== null ? lastForm : emptyForm)

  const checkLength = (val) => {
    if (JSON.stringify(val).length > 65000) {
      alert("You've Exceeded the Maximum Length of this Textbox.")
      return true
    }
    return false
  }

  const submitFormHandler = async () => {
    let errors = []

    if (formInfo.ministry.trim() === '') {
      errors.push('Enter the Name of Your Ministry.')
    }

    if (formInfo.coreValues.html === '<p><br></p>' || formInfo.coreValues.html === '') {
      errors.push("Add Your Core Values (Question 1).")
    }
    if (formInfo.report.html === '<p><br></p>' || formInfo.report.html === '') {
      errors.push("Add Your Exec/Elder or Type N/A (Question 2).")
    }
    if (formInfo.orgMap.html === '<p><br></p>' || formInfo.orgMap.html === '') {
      errors.push("Add Your Organizational Map (RACI) (Question 3).")
    }
    if (formInfo.ministryArea.html === '<p><br></p>' || formInfo.ministryArea.html === '') {
      errors.push("Add Your Ministry Area Info (Question 4).")
    }
    if (errors.length > 0) {
      let msg = ''
      errors.forEach((error, index) => {
        if (index !== 0) {
          msg += '\n'
        }
        msg += error
      })
      alert(msg)
    } else {
      let data = {
        ministry: formInfo.ministry,
        coreValues: JSON.stringify(formInfo.coreValues),
        report: JSON.stringify(formInfo.report),
        orgMap: JSON.stringify(formInfo.orgMap),
        ministryArea: JSON.stringify(formInfo.ministryArea),
      }

      await axios
        .post(`${api.path}/staff_portal_api/v1/createMyMinistryForm`,
          data
        )
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      {formInfo.id && (
        <GridItem xs={12} style={{ textAlign: 'right' }}>
          <Button color="primary"
            onClick={() => location.href = `/portal/MyProfile/HBAB/Ministry/${formInfo.id}?type=Me`}
          >
            View Forms
          </Button>
        </GridItem>
      )}
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <h4 style={{ fontWeight: 'bold' }}>My Ministry</h4>
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <p style={{ fontSize: '18px' }}><strong>Name:</strong> {user.firstName} {user.lastName}</p>
            </GridItem>
            <GridItem xs={12}>
              <p style={{ fontSize: '18px' }}><strong>Email:</strong> {user.email}</p>
            </GridItem>
            <GridItem xs={12}>
              <div style={{ display: ' flex', alignItems: 'center', marginTop: '10px' }}>
                <p style={{ fontSize: '18px', paddingRight: '10px' }}><strong>Ministry:</strong></p>
                <TextField
                  label='My Ministry'
                  variant="outlined"
                  value={formInfo.ministry}
                  onChange={e => {
                    setFormInfo(prevState => {
                      return update(prevState, {
                        ministry: { $set: e.target.value }
                      })
                    })
                  }}
                  fullWidth
                  required
                />
              </div>
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>1.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>What are your ministrys 5 focus areas?</h4>
              </div>
              <div style={{ paddingLeft: '30px' }}>
                <p>
                  a. Definition of each item?<br />
                  b. Who owns it?
                </p>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.coreValues.html}
                defaultValue={formInfo.coreValues.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      coreValues: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>2.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Exec/Elder Communcation and Responsibilities</h4>
              </div>
              <div style={{ paddingLeft: '30px' }}>
                <p>
                  a. Name <br />
                  b. Communication method & frequency?<br />
                  c. Which ministry responsibilities fall under and/or are discussed with them?
                </p>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                placeholder={'Example:\nJon Doe\nMeet bi-weekly in-person Mondays @ 2pm\n1. Discuss the direction of upcoming weekend Events\n2. ... \n3. ...'}
                value={formInfo.report.html}
                defaultValue={formInfo.report.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      report: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>3.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Organizational Map (RACI)</h4>
              </div>
              <div style={{ paddingLeft: '30px' }}>
                <p>
                  a. Identify who is accountable for key processes/activities/tasks
                </p>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.orgMap.html}
                defaultValue={formInfo.orgMap.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      orgMap: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <div style={{ display: 'flex' }}>
                <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>4.</h4>
                <h4 style={{ fontWeight: 'bolder' }}>Ministry Area Key Processes/Responsibilities/Resources</h4>
              </div>
              <div style={{ paddingLeft: '30px' }}>
                <p>
                  a. Identify where key processes description are tracked and can be found (e.g. Monday, Word Doc, etc…)
                </p>
              </div>
              <ReactQuill
                style={{ height: '250px', paddingBottom: '50px', marginLeft: '20px' }}
                modules={modules}
                formats={formats}
                value={formInfo.ministryArea.html}
                defaultValue={formInfo.ministryArea.delta}
                onChange={(content, delta, source, editor) => {
                  setFormInfo(prevState => {
                    let newVal = {
                      html: editor.getHTML(),
                      content: editor.getContents()
                    }
                    if (checkLength(newVal)) {
                      return prevState
                    }
                    return update(prevState, {
                      ministryArea: { $merge: newVal }
                    })
                  })
                }}
              />
            </GridItem>

            <GridItem xs={12} style={{ textAlign: 'right' }}>
              <Button color="primary"
                onClick={submitFormHandler}
              >
                Save
              </Button>
            </GridItem>

          </GridContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
const MyMinistry = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <GridContainer>
        <GridItem xs={12} lg={8}>
          <MyMinistryComponent {...props} />
        </GridItem>
      </GridContainer>
    </React.Suspense>
  )
}

export default MyMinistry