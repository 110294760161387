import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";

import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import ManageOnlineForms from "./ManageOnlineForms";
import ManageLinksPage from "./ManageLinksPage";
import ManageODLinksPage from "./ManageODLinksPage";

const ListAdminUtilsComp = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "Online Forms",
      tabContent: (
        <ManageOnlineForms />
      ),
    },
    {
      tabName: "Links",
      tabContent: (
        <ManageLinksPage />
      ),
    },
    {
      tabName: "ER Links",
      tabContent: (
        <ManageODLinksPage />
      ),
    },
  ]
  return (
    <React.Fragment>
      <GridContainer>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </GridContainer>
    </React.Fragment>
  )
}

const ListAdminUtilsLoading = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: 'Online Forms',
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: 'Links',
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: 'ER Links',
      tabContent: <Loading color="blue" />,
    },
  ]
  return (
    <React.Fragment>
      <GridContainer>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </GridContainer>
    </React.Fragment>
  )
}


const ListAdminUtils = (props) => {
  return (
    <React.Suspense fallback={<ListAdminUtilsLoading {...props} />}>
      <ListAdminUtilsComp {...props} />
    </React.Suspense>
  )
}

export default ListAdminUtils